@import "~antd/dist/antd.css";

#root {
  height: 100%;
}

.content {
  padding: 20px;
  margin: 20px;
  min-height: 800px;
  background-color: #fff;
}

.breadcrumb {
  margin-bottom: 16px;
}
